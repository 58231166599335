import config from "../config";
import Resource from "./resource";

export class SendMsg extends Resource {
  async createApiHandler(body, subject) {
    let response = await fetch(`${config.API_URL}/communication/email`, {
      method: "POST",
      body: JSON.stringify({
        body,
        subject,
      }),
    });

    return response.json();
  }
}
