import config from "../config";
import Resource from "./resource";

export class File extends Resource {
  async createApiHandler(file) {
    let response = await fetch(`${config.API_URL}/file/${file.file_name}`, {
      method: "POST",
      body: JSON.stringify({
        file_name: file.file_name,
        file_path: file.file_path,
      }),
    });

    return response.json();
  }
}
