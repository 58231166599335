import React from "react";
import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
const MainLayout = ({ children, title, clientCode }) => {
  return (
    <div>
      <Header title={title}></Header>
      <Container>{children}</Container>
      <Footer clientCode={clientCode}></Footer>
      <ToastContainer />
    </div>
  );
};

export default MainLayout;
