import moment from "moment";
import { useState } from "react";

const toolTipStyle = {
  fontFamily: "lato",
  fontSize: 11,
  marginTop: -15,
  width: 150,
  backgroundColor: "white",
  position: "absolute",
  transform: "translate(-50%, -50%)",
};
export function Handle({
  handle: { id, value, percent },
  getHandleProps,
  options,
  isActive,
  disabled,
}) {
  const [mouseOver, setMouseOver] = useState(false);

  const onMouseEnter = () => {
    setMouseOver(true);
  };

  const onMouseLeave = () => {
    setMouseOver(false);
  };
  // This function is used to convert date in a format
  const getDisplayTick = (data) => {
    if (options.edgeTicks.type === "date" && data) {
      return moment
        .utc(value)
        .format(options.edgeTicks.displayFormat || "MMM DD, YY");
    }
    return data;
  };
  return (
    <>
      <div
        style={{
          left: `${percent}%`,
          position: "absolute",
          marginTop: -23,
          zIndex: 2,
          width: 15,
          height: 23,
          border: 0,
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: "#D0D4D9",
          color: "#333",
        }}
        {...getHandleProps(id, {
          onMouseEnter,
          onMouseLeave,
        })}
      >
        {mouseOver && <div style={toolTipStyle}>{getDisplayTick(value)}</div>}
      </div>
    </>
  );
}
