import React from "react";
import { Table } from "react-bootstrap";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import LoadingTableCell from "../LoadingTableCell";
import styles from "./index.module.css";

const statMapping = [
  // {
  //   title: "General",
  //   childrens: [
  //     {
  //       title: "ALSFRS",
  //       keys: ["ALFRS_LAST_SESSION", "ALFRS_LAST_MONTH", "ALFRS_ALL_TIME"],
  //     },
  //     {
  //       title: "ROADS",
  //       keys: ["ROADS_LAST_SESSION", "ROADS_LAST_MONTH", "ROADS_ALL_TIME"],
  //     },
  //   ],
  // },
  {
    title: "Project: Everything ALS Speech Study",
    childrens: [
      {
        title: "Words Per Minute",
        keys: ["LAST_SESSION_WPM", "AVG_WPM_LAST_MONTH", "AVG_WPM_ALL_TIME"],
      },

      {
        title: "Puh Tuh Kuh Syllables",
        keys: [
          "LAST_SESSION_DDK_AMR_DURATION",
          "AVG_DDK_AMR_DURATION_LAST_MONTH",
          "AVG_DDK_AMR_DURATION_ALL_TIME",
        ],
      },
      {
        title: "Loudness (dB)",
        keys: [
          "LAST_SESSION_AH_DURATION",
          "AVG_AH_DURATION_LAST_MONTH",
          "AVG_AH_DURATION_ALL_TIME",
        ],
      },
    ],
  },
];

const KeyStats = ({ clientCode }) => {
  const { report } = useStores();

  const fetchKeyStatsResponse = useQuery(
    "fetchKeyStats",
    () =>
      report.listApiHandler({ clientCode, queryParams: { stat: "keystat" } }),
    {
      onError: (err) => {
        toast.error("Unable to fetch data");
      },
    }
  );

  const renderCell = (keys) => {
    return keys.map((keyName) => {
      return (
        <LoadingTableCell
          key={keyName}
          loading={fetchKeyStatsResponse.isFetching}
        >
          {Math.round(fetchKeyStatsResponse.data?.[keyName] || 0)}
        </LoadingTableCell>
      );
    });
  };

  const renderStat = (title, keys) => {
    return (
      <tr key={title}>
        <th scope="row">{title}</th>
        {renderCell(keys)}
      </tr>
    );
  };

  const renderStatHeader = (title) => {
    return (
      <tr key={title}>
        <th scope="row">{title}</th>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  };
  return (
    <div className={styles.keyStatistics}>
      <div className={styles.borderUpper}>
        <Table bordered className={styles.tableKeyStats}>
          <thead>
            <tr>
              <th scope="col">Data</th>
              <th scope="col">Last Session</th>
              <th scope="col">Last 30 Days Average</th>
              <th scope="col">All Time Average</th>
            </tr>
          </thead>
          {statMapping.map((stat) => {
            return (
              <tbody key={stat.title}>
                {renderStatHeader(stat.title)}
                {stat.childrens.map((children) => {
                  return renderStat(children.title, children.keys);
                })}
              </tbody>
            );
          })}
        </Table>
      </div>
    </div>
  );
};

export default KeyStats;
