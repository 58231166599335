import { Field } from "formik";
import React from "react";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
const valid = function (current) {
  return true;
};

const FormikDateTimePicker = ({
  controlId,
  label,
  name,
  className,
  value,
  placeholder,
  required,
  ...props
}) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const isValid = !form.errors[field.name];

        return (
          <>
            <span>{label}</span>
            <Datetime
              {...props}
              name={name}
              placeholder={placeholder}
              value={field.value}
              onChange={(time) => {
                form.setFieldValue(field.name, time.valueOf());
              }}
              isValidDate={valid}
              onBlur={() => form.setFieldTouched(field.name, true)}
            />
            <span
              className={`invalid-feedback ${!isValid ? "d-block" : "d-none"}`}
            >
              {form.errors[field.name]}
            </span>
          </>
        );
      }}
    />
  );
};

export default FormikDateTimePicker;
