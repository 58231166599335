import { AnimatePresence } from "framer-motion";
import React, { Fragment, Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, Switch } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import Dashboard from "./components/Dashboard";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const Routes = () => {
  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <PropagateLoader color={"#5383ff"} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Route exact path="/" component={Dashboard} />
          </Switch>
        </Suspense>
      </AnimatePresence>
    </QueryClientProvider>
  );
};

export default Routes;
