import { get as _get } from "lodash";
import { flow, makeObservable, observable } from "mobx";
import { createTransformer } from "mobx-utils";
export class Resource {
  loading = false;
  loaded = false;
  error = {};
  data = [];
  datum = {};

  constructor({ ...store }) {
    this.store = store;

    makeObservable(this, {
      loading: observable,
      loaded: observable,
      error: observable,
      data: observable,
      datum: observable,
    });
  }

  set = flow(function* (id) {
    this.datum[id] = {};
  });

  fetchList = flow(function* (params) {
    const { refreshList } = params || {};

    this.loading = true;
    try {
      let results = yield this.listApiHandler(params);
      if (refreshList === true) this.datum = {};

      this.data = results;
      this.loading = false;
      this.loaded = true;
    } catch (e) {
      this.error = e;
    }
  });
  /**
   *
   * @param {*} newData
   * @param {*} oldData
   * @param {*} _index
   * @param {*} _results
   */

  listMapper(newData, oldData) {
    return {
      ...(oldData ? oldData : {}),
      ...(newData ? newData : {}),
    };
  }
  async listApiHandler() {
    return [];
  }

  get = createTransformer(([defaultVal, ...path]) => {
    const val = _get(this.datum, path, defaultVal);
    if (val) return val;
    return defaultVal;
  });
}
export default Resource;
