import React, { useState,useEffect } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useQuery } from "react-query";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import config from "../../config";
import { useStores } from "../../hooks/useStores";
import styles from "./index.module.scss";
const ALSFRS_KEY = "alsfrsr";

const mapToNode = (text, customData, showCheckbox = true) => {
  return {
    label: text,
    value: customData.id,
    customData,
    showCheckbox,
  };
};

const treeData = [
  {
    ...mapToNode(
      "GENERAL",
      {
        id: "GENERAL",
      },
      false
    ),
    children: [
      {
        ...mapToNode("ALSFRS", {
          id: ALSFRS_KEY,
          value: ALSFRS_KEY,
          title: "ALSFRS",
          legend: "ALSFRS",
          parent: "GENERAL",
        }),

        children: [
          // {
          //   ...mapToNode("All Categories", {
          //     id: "All",
          //     value: "alsfrsr",
          //     title: "ALSFRS",
          //     legend: "ALSFRS",
          //     index: 0,
          //     level: 2,
          //     parent: "alsfrsr",
          //   }),
          // },
        ],
      },
      {
        ...mapToNode("ROADS", {
          id: "ROADS",
          value: "roads",
          title: "ROADS",
          legend: "ROADS",
          parent: "GENERAL",
        }),
      },
    ],
  },
  {
    ...mapToNode(
      "E-ALS SPEECH",
      {
        id: "E-ALS SPEECH",
      },
      false
    ),
    children: [
      {
        ...mapToNode("Words Per Minute", {
          id: "WPM",
          value: "wpm",
          title: "Words Per Minute",
          legend: "WPM",
          parent: "E-ALS SPEECH",
        }),
      },
      {
        ...mapToNode("Puh Tuh Kuh Syllables", {
          id: "Puh Tuh Kuh Syllables",
          value: "ddrDuration",
          title: "Puh Tuh Kuh Syllables",
          legend: "Puh Tuh Kuh Syllables",
          parent: "E-ALS SPEECH",
        }),
      },
      {
        ...mapToNode("Loudness (dB)", {
          id: "Loudness (dB)",
          value: "ahLoudness",
          title: "Loudness (dB)",
          legend: "Loudness (dB)",
          parent: "E-ALS SPEECH",
        }),
      },
    ],
  },
];
const DEFAULT_CHART_SELECTED = ["WPM", "alsfrsr"];
const NODE_SELECTABLE = ["GENERAL", "E-ALS SPEECH"];
const ChartOptions = ({ onSelect, clientCode, onDefaultSelection }) => {
  const { category } = useStores();
  const [chartOptionsData, setChartOptionsData] = useState(null);
  const [checked, setChecked] = useState(DEFAULT_CHART_SELECTED);
  const [expanded, setExpanded] = useState([...NODE_SELECTABLE, ALSFRS_KEY]);

  const [showSeriesError, setShowSeriesError] = useState(false);
  function loadCategories(allCat) {
    let data = [...treeData];
    let defaultCheckOptions = [...DEFAULT_CHART_SELECTED];
    let alfrsNodes = [...data[0].children[0].children];
    for (let cat of allCat) {
      if (!alfrsNodes.find((n) => n.value === cat.VALUE)) {
        defaultCheckOptions.push(cat.VALUE);
        alfrsNodes.push(
          mapToNode(cat.NAME, {
            id: cat.VALUE,
            value: data[0].children[0].value,
            title: cat.NAME,
            legend: cat.NAME,
            catValue: cat.VALUE,
            type: "category",
            parent: ALSFRS_KEY,
          })
        );
      }
    }
    
    // alfrsNodes[0].state.selected = true;
    // Default selected
    //  data[0].children[0].state.selected = true;
    //  data[1].children[0].state.selected = true;
    data[0].children[0].children = alfrsNodes;
    setChecked(defaultCheckOptions);
    setChartOptionsData(data);
    onDefaultSelection([
      data[0].children[0].customData,
      data[1].children[0].customData,
    ]);
  }

  useEffect(() => {
    // console.log('process.env.REACT_APP_STAGE',process.env.REACT_APP_STAGE);
  }, [0])

  const fetchCategory = useQuery(
    ["fetchCategory"],
    () =>
      category.listApiHandler({
        clientCode,
        queryParams: {
          stat: "category",
          surveyId: "ALSFRSR",
        },
      }),
    {
      onSuccess: (data) => {
        if (data && Array.isArray(data)) {
          loadCategories([...data]);
        }
      },
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );

  const searchNode = (nodeData) => {
    let selectedNode = null;
    for (let node of chartOptionsData) {
      selectedNode = findNode(node, nodeData, selectedNode);
    }
    return selectedNode;
  };

  const onNodeSelection = (nodeData, treeEventData) => {
    // check if atmost max charts are selected
    
    let selectedNodeLength = treeEventData.filter(
      (t) => !NODE_SELECTABLE.includes(t)
    ).length;
    if (treeEventData.includes(ALSFRS_KEY)) {
      let alsfrsNode = searchNode({ value: ALSFRS_KEY });
      if (alsfrsNode && alsfrsNode.children) {
        selectedNodeLength = selectedNodeLength - alsfrsNode.children.length;
      }
    }
    
    // console.log('alsfrsNode',alsfrsNode);
    if (selectedNodeLength > config.NUMBER_OF_CHARTS) {
      //toast.error("Please select 2 series");
      setShowSeriesError(true);
      setTimeout(() => {
        setShowSeriesError(false);
      }, 1000);
    } else {
      let events = [];
      if (!checked.includes(ALSFRS_KEY) && treeEventData.includes(ALSFRS_KEY)) {
        // one of the child is deselected
        let selectedNode = searchNode({ value: ALSFRS_KEY });
        if (selectedNode && selectedNode.children) {
          for (let c of selectedNode.children) {
            if (checked.includes(c.value)) {
              events.push({
                state: { selected: false },
                customData: c.customData,
              });
            }
          }
        }
      }

      let selectedNode = searchNode(nodeData);
      if (selectedNode && selectedNode.customData) {
        if (nodeData.checked) {
          events.push({
            state: { selected: true },
            customData: selectedNode.customData,
          });
        } else {
          events.push({
            state: { selected: false },
            customData: selectedNode.customData,
          });
        }
      }

      // Check if all categories are selected for alsfrs or not
      if (
        checked.includes(ALSFRS_KEY) &&
        !treeEventData.includes(ALSFRS_KEY) &&
        nodeData.value !== ALSFRS_KEY
      ) {
        // one of the child is deselected
        let selectedNode = searchNode({ value: ALSFRS_KEY });
        if (selectedNode && selectedNode.customData) {
          events.push({
            state: { selected: false },
            customData: selectedNode.customData,
          });
        }
        if (selectedNode && selectedNode.children) {
          for (let c of selectedNode.children) {
            treeEventData = treeEventData.filter((t) => t !== c.value);
          }
        }
      }
      if (events && events.length > 0) {
        onSelect(events);
      }
      setChecked(treeEventData);
    }
  };

  const findNode = (currentNode, selectedNode, searchedNode) => {
    if (currentNode?.customData?.id === selectedNode.value) {
      return currentNode;
    }
    if (
      !searchedNode &&
      currentNode.children &&
      currentNode.children?.length > 0
    ) {
      for (let node of currentNode.children) {
        let result = findNode(node, selectedNode);
        if (result != null) {
          // We've found the goal node while going down that child
          return result;
        }
      }
    }
    return searchedNode;
  };

  return (
    <>
      <BeatLoader loading={fetchCategory.isFetching}></BeatLoader>
      <div className={`${styles.chooseBox} col-xl-12 col-md-12`}>
        <span
          className={`${showSeriesError ? "text-danger fw-bolder fs-4" : ""}`}
        >
          Choose up to two data series
        </span>
      </div>
      {!fetchCategory.isFetching && chartOptionsData && checked && (
        <CheckboxTree
          showNodeIcon={false}
          nodes={chartOptionsData}
          checked={checked}
          expanded={expanded}
          checkModel={"all"}
          onCheck={(checkedNodes, node) => onNodeSelection(node, checkedNodes)}
          onExpand={(expanded) => setExpanded(expanded)}
        />
      )}
      {/* <TreeView
        data={chartOptionsData}
        color="#363547"
        onSelect={onNodeSelection}
      /> */}
    </>
  );
};

export default ChartOptions;
