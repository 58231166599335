import moment from "moment";
import React, { useState } from "react";
import TreeView from "react-bootstrap4-tree";
import { ImFilm, ImVolumeHigh } from "react-icons/im";
import { MdClose, MdFileDownload } from "react-icons/md";
import { useQuery } from "react-query";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import styles from "./index.module.scss";
const DataDownload = ({ clientCode }) => {
  const { report } = useStores();
  const { File } = useStores();
  const [treeData, setTreeData] = useState([]);
  const [videoUrl, setVideoUrl] = useState(null);
  let selectFile = [];

  const mapToNode = (text, customData, showSelect) => {
    return {
      text,
      showSelect,
      state: {
        expanded: false,
        selected: false,
      },
      customData,
      nodes: [],
    };
  };

  const formatDate = (date) => {
    return moment.utc(date).format("DD-MMM-YYYY HH:MM:SS");
  };
  const fetchSessionResponse = useQuery(
    "fetchSessionStats",
    () =>
      report.listApiHandler({
        clientCode,
        queryParams: { stat: "datadownload" },
      }),
    {
      onSuccess: (data) => {
        // console.log('data',data);
        // Convert data to tree structure
        if (data && data.results && data.results.length > 0) {
          // group by date
          let groupByDate = data.results.reduce((acc, current) => {
            let dateLabel = current.date;
            if (dateLabel) {
              let newDate = dateLabel.split("T")[1];
              if (newDate == "00:00:00.000Z") {
                // console.log("newDate", newDate);
                dateLabel = moment(dateLabel.split("T")[0]).format(
                  "DD-MMM-YYYY"
                );
              } else {
                dateLabel = formatDate(current.date);
              }
            }
            let parent = mapToNode(
              dateLabel ? dateLabel : formatDate(current.date),
              { id: current.date },
              false
            );
            if (current.date in acc) {
              parent = acc[current.date];
            }
            let sessionNode = mapToNode(
              current.session_id,
              { id: current.session_id },
              false
            );
            // Get all files
            for (let file of current.files.filter(
              (f) => !f.file_name.includes("patientTurn")
            )) {
              sessionNode.nodes.push(mapToNode(getLeafNode(file), file, true));
            }
            parent.nodes.push(sessionNode);
            acc[current.date] = parent;
            return acc;
          }, {});
          let tree = Object.values(groupByDate);
          if (tree) {
            let sortTree = tree.sort((a, b) => moment(b.text) - moment(a.text));
            let lastSession = sortTree[0];
            lastSession.state.expanded = true;
            for (let i = 0; i < lastSession.nodes.length; i++)
              lastSession.nodes[i].state.expanded = true;
            setTreeData(sortTree);
          }
        }
      },
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code Not Found");
      },
    }
  );

  const getLeafNode = (file) => {
    let name = "Session Audio";
    if (file.file_name.includes("mp4")) {
      name = "Session Video";
    }
    return (
      <span>
        {name}
        {file.file_name.includes("mp4") && (
          <ImFilm
            onClick={() => playFile(file)}
            style={{ marginLeft: "5px", fill: "black" }}
          ></ImFilm>
        )}
        {!file.file_name.includes("mp4") && (
          <ImVolumeHigh
            onClick={() => playFile(file)}
            style={{ marginLeft: "5px", fill: "black" }}
          ></ImVolumeHigh>
        )}
        <MdFileDownload
          onClick={() => downloadFile(file)}
          style={{ marginLeft: "5px", fill: "black" }}
        ></MdFileDownload>
      </span>
    );
  };

  const onSelect = (event) => {
    let val = event.state.selected;

    if (val) selectFile.push(event.customData);
    else selectFile = selectFile.filter((e) => e !== event.customData);
  };

  const playFile = async (file) => {
    let response = await File.createApiHandler(file);
    if (response && response.url) {
      if (file.file_name.includes("mp4")) {
        setVideoUrl(response.url);
        document.getElementById("videofile").play();
      } else {
        let audio = new Audio(response.url);
        audio.play();
      }
    }
  };
  const downloadFile = async (file, index) => {
    let response = await File.createApiHandler(file);

    if (response && response.url) {
      setTimeout(
        function (file) {
          const link = document.createElement("a");
          link.href = response.url;
          link.id = file.file_name;
          link.target = "_blank";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        },
        500 * index,
        file
      );
    }
  };

  return (
    <div className={`${styles.allRecording} p-4`}>
      <BeatLoader loading={fetchSessionResponse.isFetching}></BeatLoader>

      <TreeView data={treeData} onSelect={onSelect} />
      {treeData?.length === 0 && <div>No Data found</div>}
      {videoUrl && (
        <div className={styles.playerWrapper}>
          <video
            className={styles.playerWrapperVideo}
            id="videofile"
            src={videoUrl}
            controls
          ></video>
          <MdClose
            fill="white"
            className={styles.closeIcon}
            onClick={() => setVideoUrl(null)}
          ></MdClose>
        </div>
      )}
    </div>
  );
};

export default DataDownload;
