import { Field } from "formik";
import React from "react";
import { Form, InputGroup } from "react-bootstrap";

const FormikTextField = ({
  as,
  md,
  size,
  controlId,
  label,
  name,
  type,
  inputGroupPrepend,
  inputGroupAppend,
  className,
  value,
  placeholder,
  required,
}) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <Form.Group
            as={as}
            md={md}
            controlId={controlId}
            className={className}
          >
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
            </Form.Label>
            <InputGroup hasValidation>
              {inputGroupPrepend}
              <Form.Control
                size={size}
                {...field}
                type={type}
                placeholder={placeholder || label}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
                value={field.value}
              />
              {inputGroupAppend}
              <Form.Control.Feedback type="invalid">
                {form.errors[field.name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      }}
    />
  );
};

FormikTextField.defaultProps = {
  type: "text",
  inputGroupPrepend: null,
};

export default FormikTextField;
