import { useState } from "react";
import { Button, Modal } from "react-bootstrap";

const LoadingModal = (props) => {
  const { title, text, buttonLoad } = props;
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{text}</Modal.Body>
        {buttonLoad && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              OK
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};
export default LoadingModal;
