import React from "react";
import logo from "../../assets/images/logo.webp";
import styles from "./index.module.scss";
const Header = ({ title }) => {
  return (
    <header className="p-3 bg-header">
      <div className="container-fluid">
        <div className="container d-flex flex-wrap align-items-center justify-content-start">
          <a
            href="https://everythingals.org"
            rel="noreferrer"
            target="_blank"
            className="d-flex align-items-center mb-2 mb-lg-0 text-white text-decoration-none"
          >
            <img
              alt="Everything ALS"
              className={styles.logoImg}
              src={logo}
            ></img>
            <span className="fs-4">{title}</span>
          </a>
        </div>
      </div>
    </header>
  );
};

export default Header;
