import React, { useEffect, useState } from "react";
import { Nav, OverlayTrigger, Tab, Tooltip } from "react-bootstrap";
import { useQuery, useQueryClient } from "react-query";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import ArrowImage from "../../assets/images/arrow.png";
import { useStores } from "../../hooks/useStores";
import ProgressionChart from "../ProgressionChart";
import styles from "./index.module.scss";

const HIDE_QUESTIONS = ["Gastrostomy"];
const BreakDown = ({ clientCode }) => {
  const queryClient = useQueryClient();
  const { report } = useStores();
  const [selectedSurveyId, setSelectedSurveyId] = useState("ALSFRSR");
  const [tableData, setTableData] = useState({
    columns: [],
    rows: [],
    groups: [],
  });

  useEffect(() => {
    if (selectedSurveyId) {
      //fetchBreakdownStatsResponse.c
      queryClient.invalidateQueries(["fetchBreakdown"]);
    }
  }, [selectedSurveyId]);

  // This function is used to map data from api to table
  const mapData = (data) => {
    let mappedData = { columns: [], rows: [], groups: [] };
    if (data && data.results && data.results.length > 0) {
      let groups = {};
      let unknownColumns = [];
      // First create groups for the table
      for (let result of data.results) {
        for (let category of result.categories) {
          if (category.group) {
            let group = { name: category.group, columns: [] };
            if (category.group in groups) {
              group = groups[category.group];
            }
            if (
              !group.columns.find((c) => c.label === category.label) &&
              !HIDE_QUESTIONS.includes(category.label)
            ) {
              group.columns.push({
                label: category.label,
                index: category.index,
                question: category.question,
              });
            }
            groups[category.group] = group;
          } else if (
            !unknownColumns.find(
              (c) =>
                c.label === category.label && !HIDE_QUESTIONS.includes(c.label)
            )
          ) {
            unknownColumns.push({
              label: category.label,
              index: category.index,
              question: category.question,
            });
          }
        }
      }
      // Now check if groups are present of not
      if (Object.keys(groups).length === 0) {
        mappedData.columns = unknownColumns.sort((a, b) => a.index - b.index);
      } else {
        mappedData.groups = Object.values(groups);
        for (let group in groups) {
          mappedData.columns.push(
            ...groups[group].columns.sort((a, b) => a.index - b.index)
          );
        }
      }

      mappedData.columns = mappedData.columns.filter(
        (c) => !HIDE_QUESTIONS.includes(c.label)
      );

      // Now based on number of columns we add either 0 or score from api
      for (let result of data.results) {
        let row = [result.date];
        let total = 0;
        for (let column of mappedData.columns) {
          let scoreData = result.categories.find(
            (c) => c.label === column.label
          );
          
          if (scoreData) {
            total += scoreData.score;
            row.push(scoreData.score);
          } else {
            // console.log('scoreData', scoreData);
            if(scoreData == '0'){
              row.push(0);
            } else {
              row.push('-');
            }
          }
        }
        row.push(total);
        mappedData.rows.push(row);
      }
    }
    setTableData(mappedData);
  };

  const fetchBreakdownStatsResponse = useQuery(
    ["fetchBreakdown", selectedSurveyId],
    () =>
      report.listApiHandler({
        clientCode,
        queryParams: { stat: "surveybreakdown", surveyId: selectedSurveyId },
      }),
    {
      onSuccess: (data) => {
        mapData(data);
      },
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );

  const renderBreakDownCell = (value = 0) => {
    let classNameSuffix = value;

    return (
      <td
        key={Math.random()}
        className={styles[`bg${selectedSurveyId}${classNameSuffix}`]}
      >
        {value}
      </td>
    );
  };
  const renderTable = () => {
    return (
      <div className={`${styles.scroolDwoml}`}>
        <BeatLoader
          loading={fetchBreakdownStatsResponse.isFetching}
        ></BeatLoader>
        {!tableData.columns?.length && (
          <div className="text-center">No Data found</div>
        )}
        {tableData.columns?.length > 0 && (
          <table
            className={`table last-tab ${
              tableData.groups?.length > 0 ? styles.groupHeader : ""
            }`}
          >
            <thead>
              {tableData.groups?.length > 0 && (
                <tr className={`${styles.groupHeader}`}>
                  <th scope="col" colSpan="1"></th>
                  {tableData.groups.map((c, index) => {
                    return (
                      <th scope="col" key={index} colSpan={c.columns?.length}>
                        {c.name}
                      </th>
                    );
                  })}
                  <th scope="col" colSpan="3"></th>
                </tr>
              )}
              <tr>
                <th scope="col">
                  {"Hover mouse over categories to view answers & scoring"}
                  <img src={ArrowImage} alt="left" />
                </th>
                {tableData.columns.map((c, index) => {
                  return (
                    <OverlayTrigger
                      key={index}
                      placement={"right"}
                      overlay={
                        <Tooltip id={`tooltip-right`}>
                          <div
                            className="text-start"
                            dangerouslySetInnerHTML={{
                              __html: c.question,
                            }}
                          ></div>
                        </Tooltip>
                      }
                    >
                      <th scope="col" key={index}>
                        {c.label}
                      </th>
                    </OverlayTrigger>
                  );
                })}
                <th scope="col">Total</th>
              </tr>
            </thead>

            <tbody id="dtVerticalScrollExample">
              {tableData.rows.map((row, index) => {
                return (
                  <tr key={index}>
                    {row.map((r, i) => {
                      if (i === 0) {
                        return (
                          <th key={i} scope="row">
                            {r}
                          </th>
                        );
                      }
                      return renderBreakDownCell(r);
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  return (
    <div className={`${styles.tableResponsive} p-4`}>
      <div className={styles.tabForTable}>
        <Tab.Container defaultActiveKey="alsfrs">
          <Nav
            variant="pills"
            defaultActiveKey="alsfrs"
            className={styles.navTabs}
          >
            <Nav.Item>
              <Nav.Link
                eventKey="disabled"
                disabled
                className={styles.navTabsDisabled}
              >
                View
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="alsfrs"
                onClick={() => setSelectedSurveyId("ALSFRSR")}
              >
                ALSFRS
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey="roads"
                onClick={() => setSelectedSurveyId("ROADS")}
              >
                ROADS
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane eventKey="alsfrs">{renderTable()}</Tab.Pane>
            <Tab.Pane eventKey="roads">{renderTable()}</Tab.Pane>
          </Tab.Content>
        </Tab.Container>
        {selectedSurveyId === "ALSFRSR" && (
          <div className="mt-4">
            <ProgressionChart clientCode={clientCode} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BreakDown;
