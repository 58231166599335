import "chartjs-adapter-date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { useQuery, useQueryClient } from "react-query";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import config from "../../config";
import { useStores } from "../../hooks/useStores";
import ChartOptions from "../ChartOptions";
import InterventionNotes from "../InterventionNotes";
import MultiSlider from "../MultiSlider";
import styles from "./index.module.css";

const NUMBER_OF_CHARTS = config.NUMBER_OF_CHARTS;
const plugin = {
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext("2d");
    var chartArea = chart.chartArea;
    ctx.save();
    ctx.fillStyle = "#FAFCFC";
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top
    );
    ctx.restore();
  },
};

const DEFAULT_DATASET = [
  {
    label: "",
    data: [],
    fill: false,
    backgroundColor: "#0057F0",
    borderColor: "#0057F0",
    yAxisID: "y",
    pointStyle: "rect",
    pointBackgroundColor: "#FFFFFF",
    pointRadius: 8,
  },
  {
    label: "",
    data: [],
    fill: false,
    backgroundColor: "#F22432",
    borderColor: "#F22432",
    yAxisID: "y1",
    pointStyle: "triangle",
    pointBackgroundColor: "#FFFFFF",
    pointRadius: 8,
    pointBorderColor: "#F22432",
  },
];

const Charts = ({ clientCode, sessionData }) => {
  const [selectedStat, setSelectedStat] = useState([null, null]);
  const [statData, setStatData] = useState([[], []]);
  const queryClient = useQueryClient();
  const [startDate, setStartDate] = useState(sessionData?.FIRST_SESSION_DATE);
  const [endDate, setEndDate] = useState(sessionData?.LAST_SESSION_DATE);
  const { interventionNotes } = useStores();

  const filterNotes = (x) => {
    return fetchNotesData?.data?.results.filter((note) => {
      // Check if note has end date or not
      let noteStart = moment
        .utc(note.STARTTIME)
        .format(config.NOTE_DATE_FORMAT)
        .valueOf();
      let xValue = moment.utc(x).format(config.NOTE_DATE_FORMAT).valueOf();
      let noteEnd = null;
      if (note.ENDTIME && note.ENDTIME !== "") {
        noteEnd = moment
          .utc(note.ENDTIME)
          .format(config.NOTE_DATE_FORMAT)
          .valueOf();
      }
      if (!noteEnd && noteStart <= xValue) {
        return true;
      }
      if (noteEnd && xValue >= noteStart && xValue <= noteEnd) {
        return true;
      }
      return false;
    });
  };

  const options = {
    onClick: graphClickEvent,
    responsive: true,
    interaction: {
      mode: "index",
      intersect: false,
    },
    stacked: false,
    plugins: {
      legend: false,
      tooltip: {
        backgroundColor: "#FFFFFF",
        titleColor: "#3F4255",
        titleFont: { family: "lato", size: 15, weight: 500 },
        bodyColor: "#3F4255",
        bodyFont: { family: "lato", size: 15, weight: 500 },
        footerColor: "blue",
        footerFont: { family: "lato", size: 15, weight: 500 },
        afterFooterColor: "blue",
        cornerRadius: 5,
        displayColors: false,
        callbacks: {
          title: function (context) {
            return "";
          },
          label: function (context) {
            let label = `${context.dataset.label}: ${context.formattedValue}`;
            return label;
          },
          footer: function (context) {
            if (context && context.length > 0) {
              if (context[0].label) {
                return `Date: ${moment(context[0].raw.x).format("MM/DD/YY")}`;
              }
              return "";
            }
            return "";
          },
          afterFooter: function (context) {
            let notes = [];
            if (
              fetchNotesData &&
              fetchNotesData.data &&
              fetchNotesData.data.results.length > 0
            ) {
              if (context && context.length > 0) {
                if (context[0].label) {
                  const notesdetail = filterNotes(context[0].raw.x);

                  notesdetail.map((note) => notes.push(note.TITLE));
                  if (notes && notes.length > 0) {
                    let finalNotes = notes.join(",");
                    if (finalNotes.length > 36) {
                      return `Notes: ${finalNotes.substring(0, 33)}...`;
                    }
                    return `Notes: ${finalNotes}`;
                  }
                  return "";
                }
              }
            }

            return "";
          },
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
          color: "#363547",
          font: {
            family: "Lato",
            size: 18,
            style: "normal",
            lineHeight: "22px",
          },
        },
        type: "time",
        distribution: "series",
        time: {
          unit: "day",
          displayFormats: {
            day: "MM/dd/yy",
          },
        },
      },
      y: {
        type: "linear",
        display: true,
        position: "left",
        title: {
          display: true,
          text: "ALSFRS",
          color: "#0057F0",
          font: {
            family: "Lato",
            size: 20,
            style: "normal",
            lineHeight: 1.2,
          },
          padding: { top: 30, left: 0, right: 0, bottom: 0 },
        },
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
      y1: {
        type: "linear",
        display: true,
        position: "right",
        title: {
          display: true,
          text: "Words Per Minute",
          color: "#F22432",
          font: {
            family: "Lato",
            size: 20,
            style: "normal",
            lineHeight: 1.2,
          },
          padding: { top: 30, left: 0, right: 0, bottom: 0 },
        },
        // grid line settings
        grid: {
          drawOnChartArea: false, // only want the grid lines for one axis to show up
        },
      },
    },
  };

  const [chartOptions, setChartOptions] = useState({ ...options });

  const [showInterventionNotes, setShowInterventionNotes] = useState(false);

  function graphClickEvent(event, array) {
    setShowInterventionNotes(true);
  }

  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const { report } = useStores();

  useEffect(() => {
    function load() {
      loadCharts();
    }
    load();
  }, [statData]);

  useEffect(() => {
    function load() {
      if (selectedStat.length > 0) {
        queryClient.invalidateQueries([
          "fetchChartOne",
          selectedStat[0]?.value,
          selectedStat[0]?.catValue || null,
        ]);
        queryClient.invalidateQueries([
          "fetchChartTwo",
          selectedStat[1]?.value,
          selectedStat[1]?.catValue || null,
        ]);
        setChartOptions({ ...options });
      } else {
        toast.error("Choose chart options");
      }
    }
    load();
  }, [selectedStat, startDate, endDate]);

  const setEditInfo = () => {
    queryClient.invalidateQueries("fetchNotesData");
    setChartOptions({ ...options });
  };

  //Hide/Unhide axis
  const hideAxis = (options) => {
    // There are 2 axis based on group index hide/unhide axis
    options.scales.y1.display = true;
    options.scales.y.display = true;
    options.scales.y1.position = "right";
    options.scales.y.position = "left";

    for (let i = 0; i < selectedStat.length; i++) {
      if (!selectedStat[i]) {
        if (i === 0) {
          options.scales.y.display = false;
          options.scales.y1.position = "left";
        } else {
          options.scales.y1.display = false;
          options.scales.y.position = "left";
        }
      }
    }
    return options;
  };

  // This function is used to map data from api to chart data
  const loadCharts = () => {
    let labels = [];
    let datasets = [];
    
    if (statData && statData.length > 0) {
      let min = null,
        max = null;
      for (let stat of statData) {
        
        for (let result of stat) {
          if (!labels.includes(result.date)) {
            labels.push(result.date);
            if (!min) {
              min = result.date;
            } else if (moment(result.date).isBefore(moment(min))) {
              min = result.date;
            }
            if (!max) {
              max = result.date;
            } else if (moment(result.date).isAfter(moment(min))) {
              max = result.date;
            }
          }
        }
      }
      let newChartOptions = { ...chartOptions };
      newChartOptions.scales.x.time.min = min;
      newChartOptions.scales.x.time.max = max;
      newChartOptions.scales.y.title.text = selectedStat[0]?.title || "";
      newChartOptions.scales.y1.title.text = selectedStat[1]?.title || "";
      // Hide if only one chart is selected
      
      newChartOptions = hideAxis(newChartOptions);
      let index = 0;
      
      for (let stat of statData) {

        let ds = DEFAULT_DATASET[index % 2];
        let statDataset = [];
        let pointBackgroundColor = [];
        if (labels?.length > 0) {
          labels.sort(
            (a, b) => moment.utc(a).valueOf() - moment.utc(b).valueOf()
          );
        }
        for (let label of labels) {
          let scoreData = stat.find((s) => s.date === label );
          if (scoreData) {
            statDataset.push({ 
              x: scoreData.date, 
              y: scoreData.score,
              extradata: scoreData.extradata,
            });
            let notes = filterNotes(
              moment.utc(label).format(config.NOTE_DATE_FORMAT)
            );
            if (notes?.length > 0) {
              pointBackgroundColor.push(ds.backgroundColor);
            } else {
              pointBackgroundColor.push("#FFFFFF");
            }
          }
        }
        
        datasets.push({
          ...ds,
          label: selectedStat[index]?.legend,
          pointBackgroundColor,
          data: statDataset,
        });
        index++;
      }
      setChartOptions(newChartOptions);
    }
    
    for(var i = 0 ; i < datasets.length; i++) {
      if(i==0) {
        // console.log('extradata',datasets[i].data);
        for(let d of datasets[i].data) {
          let total=0;
          let BULBAR=0;
          let FINEMOTOR=0;
          let GROSSMOTOR=0;
          let RESPIRATORY=0;
          let i=0;
          if(d.extradata != undefined) {
            for(let inner of d.extradata) {
              total+=Number(inner.questiongroup_score);
              if(i=='0') {
                BULBAR=inner.questiongroup_score;
              } else if(i=='1') {
                FINEMOTOR=inner.questiongroup_score;
              } else if(i=='2') {
                GROSSMOTOR=inner.questiongroup_score;
              } else if(i=='3') {
                RESPIRATORY=inner.questiongroup_score;
              }
              i++;
            }
            d.total_score=total;
            d.score=d.y;
            d.y=total;
            d.BULBAR=BULBAR;
            d.FINEMOTOR=FINEMOTOR;
            d.GROSSMOTOR=GROSSMOTOR;
            d.RESPIRATORY=RESPIRATORY;
          }
        }
      }
    }
    for(var i = 0 ; i < datasets.length; i++) {
      if(i==0) {
        for(let d of datasets[i].data) {
          if(selectedStat[0] != null) {
            if(selectedStat[0].id == 'BULBAR') {
              d.y=d.BULBAR;
            }
            if(selectedStat[0].id == 'FINEMOTOR') {
              d.y=d.FINEMOTOR;
            }
            if(selectedStat[0].id == 'GROSSMOTOR') {
              d.y=d.GROSSMOTOR;
            }
            if(selectedStat[0].id == 'RESPIRATORY') {
              d.y=d.RESPIRATORY;
            }
          }
        }
      }
    }
    // console.log('my statistic data',datasets);
    setData({ ...data, labels, datasets });
  }

  const handleSliderDateChange = (data = []) => {
    setStartDate(data[0]);
    setEndDate(data[1]);
  };

  const getChartData = (index) => {
    let queryParams = {
      stat: "charts",
      chartparams: selectedStat[index]?.value,
      startdate: startDate,
      enddate: endDate,
    };
    if (selectedStat[index]?.catValue) {
      queryParams.categories = selectedStat[index]?.catValue;
    }
    return report.listApiHandler({
      clientCode,
      queryParams,
    });
  };
  const fetchChartOne = useQuery(
    [
      "fetchChartOne",
      selectedStat[0]?.value,
      startDate,
      endDate,
      selectedStat[0]?.catValue || null,
    ],
    () => getChartData(0),
    {
      onSuccess: (data) => {
        statData[0] = data?.results || [];
        setStatData([...statData]);
        // console.log('response data',data);
      },
      onError: (err) => {
        console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );
  const fetchChartTwo = useQuery(
    [
      "fetchChartTwo",
      selectedStat[1]?.value,
      startDate,
      endDate,
      selectedStat[1]?.catValue || null,
    ],
    () => getChartData(1),
    {
      onSuccess: (data) => {
        statData[1] = data?.results || [];
        setStatData([...statData]);
        // let arr=[];
        // console.log('statData',statData)
      },
      onError: (err) => {
        toast.error("Unable to fetch data");
      },
    }
  );

  //This function is used to fecth Intervention Notes
  const fetchNotesData = useQuery(
    ["fetchNotesData"],
    () =>
      interventionNotes.listApiHandler({
        clientCode,
        queryParams: {
          startdate: startDate,
          enddate: endDate,
        },
      }),
    {
      onSuccess: (data) => {
        // if (data && data.results && data.results.length > 0) {
        //   setNotesData(data);
        //   setChartOptions({ ...options });
        // }
      },
    },
    {
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );
  const validateStats = () => {
    if (!selectedStat[0] && !selectedStat[1]) return true;
  };

  const renderCharts = () => {
    const val = validateStats();

    if (!val)
      return <Line data={data} options={chartOptions} plugins={[plugin]} />;
    else return <Line options={chartOptions} plugins={[plugin]} />;
  };

  const onDefaultSelection = (stats) => {
    setSelectedStat(stats);
  };

  const onSelect = (events) => {
    
    // console.log('events',events);
    let newSelectedStat = [...selectedStat];
    for (let e of events) {
      if (e.state.selected) {
        let foundNullNode = false;
        for (let i = 0; i < newSelectedStat.length; i++) {
          if (newSelectedStat[i] === null) {
            newSelectedStat[i] = { ...e.customData };
            foundNullNode = true;
            break;
          }
        }
        if (!foundNullNode) {
          newSelectedStat.push({ ...e.customData });
        }
      } else {
        for (let i = 0; i < newSelectedStat.length; i++) {
          if (
            newSelectedStat[i] !== null &&
            newSelectedStat[i].id === e.customData.id
          ) {
            // If we are unselecting > nUmber of charts then we simply pop
            newSelectedStat[i] = null;
            if (i >= NUMBER_OF_CHARTS) {
              newSelectedStat.splice(i, 1);
            }
          }
        }
        if (newSelectedStat.length > NUMBER_OF_CHARTS) {
          for (let i = 0; i < NUMBER_OF_CHARTS; i++) {
            // check if any of stat is null
            if (newSelectedStat[i] === null) {
              // Copy the 3rd row here
              newSelectedStat[i] = newSelectedStat.pop();
            }
          }
        }
      }
    }
    setSelectedStat(newSelectedStat);
  };

  const renderSlider = () => {
    return (
      <MultiSlider
        options={{
          edgeTicks: {
            type: "date",
            displayFormat: "MMM DD, YY",
            start: sessionData?.FIRST_SESSION_DATE,
            end: sessionData?.LAST_SESSION_DATE,
          },
        }}
        onChange={handleSliderDateChange}
      ></MultiSlider>
    );
  };
  const renderSelectChart = () => {
    return (
      <>
        <ChartOptions
          onSelect={onSelect}
          clientCode={clientCode}
          onDefaultSelection={onDefaultSelection}
        ></ChartOptions>
      </>
    );
  };
  const renderUserTips = () => {
    return (
      <Row>
        <Col xl={12}>
          <div className={styles.dataFooter}>
            {/* {console.log('process.env.REACT_APP_STAGE',process.env.REACT_APP_STAGE)} */}
            <h4>User Tips:</h4>
            <ul>
              <li>
                <span>1.</span>
                <p>
                  Hover your mouse over the charts data points markers to see
                  the value for that entry.
                </p>
              </li>
              <li>
                <span>2.</span>
                <p>
                  {" "}
                  Adjust the start and stop sliders below the graph to see a
                  specific date range.
                </p>
              </li>
            </ul>
          </div>
        </Col>
      </Row>
    );
  };
  return (
    <>
      <BeatLoader loading={fetchNotesData.isFetching}></BeatLoader>
      {!fetchNotesData.isFetching && (
        <div className={styles.topBox}>
          <Row>
            <Col
              xl={8}
              md={8}
              className="d-flex justify-content-between align-items-center flex-column"
            >
              <BeatLoader
                loading={
                  fetchChartOne.isFetching ||
                  fetchChartTwo.isFetching ||
                  fetchNotesData.isFetching
                }
              ></BeatLoader>

              {!fetchNotesData.isFetching && renderCharts()}
              {sessionData && renderSlider()}
            </Col>
            <Col xl={4} md={4} className={`pl-0 ${styles.rightBox}`}>
              {clientCode && renderSelectChart()}
            </Col>
          </Row>
          {renderUserTips()}

          {showInterventionNotes && (
            <InterventionNotes
              showModal={showInterventionNotes}
              onClose={() => setShowInterventionNotes(false)}
              clientCode={clientCode}
              notesData={fetchNotesData?.data || []}
              onEdit={setEditInfo}
            ></InterventionNotes>
          )}
        </div>
      )}
    </>
  );
};

export default Charts;
