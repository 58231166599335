import { Router } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import history from "./history";
import Routes from "./Routes";
function App() {
  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;
