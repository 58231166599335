import "chartjs-adapter-date-fns";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Line } from "react-chartjs-2";
import { useQuery } from "react-query";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import trendlineLinear from "../../plugins/chartjs-plugin-trendline";

const plugin = {
  beforeDraw: (chart) => {
    const ctx = chart.canvas.getContext("2d");
    var chartArea = chart.chartArea;
    ctx.save();
    ctx.fillStyle = "#FAFCFC";
    ctx.fillRect(
      chartArea.left,
      chartArea.top,
      chartArea.right - chartArea.left,
      chartArea.bottom - chartArea.top
    );
    ctx.restore();
  },
};

let options = {
  responsive: true,

  interaction: {
    mode: "index",
    intersect: false,
  },
  stacked: false,
  plugins: {
    title: {
      font: { family: "lato", size: 18, lineHeight: "22px", weight: 500 },
      display: true,
      align: "start",
      text: "Progression Speed (- ALSFRSR / month)",
    },

    legend: false,
    tooltip: {
      backgroundColor: "#FFFFFF",
      titleColor: "#3F4255",
      titleFont: { family: "lato", size: 15, weight: 500 },
      bodyColor: "#3F4255",
      bodyFont: { family: "lato", size: 15, weight: 500 },
      footerColor: "#3F4255",
      footerFont: { family: "lato", size: 15, weight: 500 },
      cornerRadius: 5,
      displayColors: false,
      callbacks: {
        title: function (context) {
          return "";
        },
        label: function (context) {
          return `Progression Speed: ${context.formattedValue}`;
        },
        footer: function (context) {
          if (context && context.length > 0) {
            if (context[0].label) {
              return `Date: ${moment(context[0].raw.x).format("MM/DD/YY")}`;
            }
            return "";
          }
          return "";
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: "Date",
        color: "#3f4255",
        font: {
          family: "Lato",
          size: 18,
          style: "normal",
          lineHeight: "22px",
        },
      },
      type: "time",
      distribution: "series",
      time: {
        unit: "day",
        displayFormats: {
          day: "MM/dd/yy",
        },
      },
    },
    y: {
      type: "linear",
      display: true,
      position: "left",
      title: {
        display: true,
        text: "",
        color: "#3f4255",
        font: {
          family: "Lato",
          size: 20,
          style: "normal",
          lineHeight: 1.2,
        },
        padding: { top: 30, left: 0, right: 0, bottom: 0 },
      },
      // grid line settings
      grid: {
        drawOnChartArea: true, // only want the grid lines for one axis to show up
      },
    },
  },
};

const DEFAULT_DATASET = {
  label: "",
  data: [],
  fill: false,
  backgroundColor: "#018ffd",
  borderColor: "#018ffd",
  yAxisID: "y",
  tension: 0.5,
  pointStyle: "circle",
  pointRadius: 8,
  trendlineLinear: {
    style: "#cedffc",
    lineStyle: "line",
    width: 5,
  },
};
const ProgressionChart = ({ clientCode }) => {
  const [statData, setStatData] = useState([]);
  const [data, setData] = useState({
    labels: [],
    datasets: [],
  });
  const { report } = useStores();

  useEffect(() => {
    function load() {
      loadCharts();
    }
    load();
  }, [statData]);

  const loadCharts = () => {
    let labels = [];
    let datasets = [];
    if (statData && statData.length > 0) {
      let min = null,
        max = null;

      for (let result of statData) {
        if (!labels.includes(result.date)) {
          labels.push(result.date);
          if (!min) {
            min = result.date;
          } else if (moment(result.date).isBefore(moment(min))) {
            min = result.date;
          }
          if (!max) {
            max = result.date;
          } else if (moment(result.date).isAfter(moment(min))) {
            max = result.date;
          }
        }
      }

      options.scales.x.time.min = min;
      options.scales.x.time.max = max;

      // for (let stat of statData) {
      let statDataset = [];

      if (labels?.length > 0) {
        labels.sort(
          (a, b) => moment.utc(a).valueOf() - moment.utc(b).valueOf()
        );
      }
      for (let label of labels) {
        let scoreData = statData.find((s) => s.date === label);
        if (scoreData) {
          statDataset.push({
            x: scoreData.date,
            y: scoreData.progressionSpeed,
          });
        }
      }
      datasets.push({
        ...DEFAULT_DATASET,
        data: statDataset,
      });
    }

    setData({ ...data, labels, datasets });
  };

  const fetchProgressChart = useQuery(
    ["fetchProgressChart"],
    () =>
      report.listApiHandler({
        clientCode,
        queryParams: {
          stat: "charts",
          chartparams: "progressionspeed",
        },
      }),
    {
      onSuccess: (data) => {
        setStatData(data?.results);
      },
      onError: (err) => {
        // console.log(err.message);
        toast.error("Patient Code not found");
      },
    }
  );

  return (
    <div>
      <Row>
        <Col className="d-flex justify-content-between align-items-center flex-column">
          <BeatLoader loading={fetchProgressChart.isFetching}></BeatLoader>
          <Line
            data={data}
            options={options}
            plugins={[plugin, trendlineLinear]}
          />
        </Col>
      </Row>
    </div>
  );
};
export default ProgressionChart;
