export function Track({ source, target, getTrackProps }) {
  return (
    <div
      style={{
        position: "absolute",
        top: -23,
        height: 23,
        zIndex: 1,
        backgroundColor: "#E9ECF0",
        cursor: "pointer",
        left: `${source.percent}%`,
        width: `${target.percent - source.percent}%`,
      }}
      {
        ...getTrackProps() /* this will set up events if you want it to be clickeable (optional) */
      }
    />
  );
}
