import React, { useState } from "react";
import { Button, Container } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import Box from "../../layouts/box";
import styles from "./index.module.css";

const Footer = ({ clientCode }) => {
  const [isLoading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [msgData, setMsgData] = useState();
  const { SendMsg } = useStores();

  const writeMessage = (event) => {
    let msg = event.target.value;
    setLoading(true);
    setMsgData(msg);
  };
  const handleClick = async () => {
    //call api to send message data
    if (msgData) {
      setSending(true);
      let response = await SendMsg.createApiHandler(
        msgData,
        `Comment from ${clientCode}`
      );
      toast.success(response.message);
      setSending(false);
    } else {
      // console.log("Message not found");
    }
  };
  return (
    <>
      <Container className="my-4">
        <Box className="footer-box">
          <div className={styles.doyouhave}>
            <div>
              <form>
                <Form.Group>
                  <Form.Label className="font-weight-bold">
                    Do you have comments, questions, or suggestions? Please send
                    them to us via the box below. Thank you!
                  </Form.Label>
                  <BeatLoader loading={sending}></BeatLoader>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Write comment.."
                    onChange={writeMessage}
                  />
                </Form.Group>
              </form>
            </div>
            <div className="text-end">
              <Button
                className="mt-3"
                disabled={!isLoading}
                onClick={handleClick}
              >
                Send{" "}
              </Button>
            </div>
          </div>
        </Box>
      </Container>

      <div className="text-white text-center bg-header py-3">
        <a
          href="https://everythingals.org"
          className="text-white"
          target="_blank"
          rel="noreferrer"
        >
          EverythingALS Website
        </a>
      </div>
    </>
  );
};

export default Footer;
