import config from "../config";
import Resource from "./resource";

export class InterventionNotes extends Resource {
  async createApiHandler(params, body) {
    let response = await fetch(`${config.API_URL}/notes/${params}`, {
      method: "POST",
      body: JSON.stringify({
        ...body,
      }),
    });
    return response.json();
  }

  async listApiHandler(params) {
    let url = `${config.API_URL}/notes/${params.clientCode}`;
    if (params.queryParams) {
      let qp = [];
      for (let key in params.queryParams) {
        qp.push(`${key}=${params.queryParams[key]}`);
      }
      url = url + `?${qp.join("&")}`;
    }
    let response = await fetch(url);
    return response.json();
  }

  async editApiHandler(params, body) {
    let response = await fetch(`${config.API_URL}/notes/${params}`, {
      method: "PUT",
      body: JSON.stringify({
        ...body,
      }),
    });
    return response.json();
  }

  async deleteApiHandler(params) {
    let url = `${config.API_URL}/notes/${params.clientCode}`;
    if (params.queryParams) {
      let qp = [];
      for (let key in params.queryParams) {
        qp.push(`${key}=${params.queryParams[key]}`);
      }
      url = url + `?${qp.join("&")}`;
    }
    let response = await fetch(url, {
      method: "DELETE",
    });
    return response.json();
  }
}
