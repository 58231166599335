import React from "react";
import { Category } from "./category";
import { File } from "./File";
import { Report } from "./report";
import { SendMsg } from "./SendMsg";
import { InterventionNotes } from "./interventionNotes";

class RootStore {
  constructor() {
    this.report = new Report(this);
    this.File = new File(this);
    this.SendMsg = new SendMsg(this);
    this.category = new Category(this);
    this.interventionNotes = new InterventionNotes(this);
  }
}
export const store = new RootStore();

export const storesContext = React.createContext(store);
