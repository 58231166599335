import moment from "moment";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaEdit, FaTrash } from "react-icons/fa";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import { useStores } from "../../hooks/useStores";
import styles from "./index.module.scss";
import UpsertNote from "./UpsertNote";

const InterventionNotes = ({
  showModal,
  onClose,
  clientCode,
  notesData,
  onEdit,
}) => {
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState("list");
  const [selectedNote, setSelectedNote] = useState(null);
  const MAX_LENGTH = 36;
  const { interventionNotes } = useStores();
  useEffect(() => {
    function load() {
      if (!(notesData && notesData.results && notesData.results.length > 0)) {
        setView("create");
      }
    }
    load();
  }, [showModal, notesData]);

  useEffect(() => {
    function load() {
      if (selectedNote) {
        setView("edit");
      }
    }
    load();
  }, [selectedNote]);

  const handleClose = () => {
    onClose();
  };

  const editNote = async (note) => {
    setSelectedNote(note);
  };

  const deleteNote = async (note) => {
    setLoading(true);
    let response = await interventionNotes.deleteApiHandler({
      clientCode,
      queryParams: {
        notesId: note.ID,
      },
    });
    toast.success(response.message);
    setView("list");
    onEdit();
    setLoading(false);
  };

  const onUpsertNodeSuccess = () => {
    onEdit();
    setView("list");
  };

  const listNotesData = () => {
    const renderSessionBox = (title) => {
      return (
        <div>
          <div className={`${styles.sessionBox} fs-5`}>
            {title.length > MAX_LENGTH
              ? `${title.substring(0, MAX_LENGTH - 3)}...`
              : `${title}`}
          </div>
        </div>
      );
    };
    return (
      <div className="mt-2">
        {notesData.results?.map((data) => {
          return (
            <div className="d-flex justify-content-between align-items-center">
              {renderSessionBox(data.TITLE)}
              <div className="d-flex justify-content-between align-items-right">
                <a onClick={() => editNote(data)} className="cursor-pointer">
                  <FaEdit />
                </a>
                <a
                  onClick={() => deleteNote(data)}
                  className="cursor-pointer text-danger ml-10"
                >
                  <FaTrash />
                </a>
              </div>
            </div>
          );
        })}
        <div className="text-center">
          <Button onClick={() => setView("create")}>New Note </Button>
        </div>
      </div>
    );
  };

  const renderEditNote = () => {
    return (
      <UpsertNote
        note={{
          note_title: selectedNote.TITLE,
          startTime: moment.utc(selectedNote.STARTTIME).valueOf(),
          endTime: selectedNote.ENDTIME
            ? moment.utc(selectedNote.ENDTIME).valueOf()
            : null,
          note_description: selectedNote.DESCRIPTION,
          id: selectedNote.ID,
        }}
        clientCode={clientCode}
        onSuccess={() => onUpsertNodeSuccess()}
      ></UpsertNote>
    );
  };

  const renderCreateNote = () => {
    return (
      <UpsertNote
        note={{
          note_title: "",
          startTime: "",
          endTime: "",
          note_description: "",
        }}
        clientCode={clientCode}
        onSuccess={() => onUpsertNodeSuccess()}
      ></UpsertNote>
    );
  };

  return (
    <>
      <BeatLoader loading={notesData.length > 0 || loading}></BeatLoader>
      <Modal show={showModal} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton closeLabel="" className={styles.modalHeader}>
          <Modal.Title>Intervention Notes:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BeatLoader loading={loading}></BeatLoader>
          {view === "list" && listNotesData()}
          {view === "create" && renderCreateNote()}
          {view === "edit" && renderEditNote()}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InterventionNotes;
