import config from "../config";
import Resource from "./resource";

export class Category extends Resource {
  async listApiHandler(params) {
    let url = `${config.API_URL}/category/${params.clientCode}`;
    if (params.queryParams) {
      let qp = [];
      for (let key in params.queryParams) {
        qp.push(`${key}=${params.queryParams[key]}`);
      }
      url = url + `?${qp.join("&")}`;
    }

    let response = await fetch(url);

    return response.json();
  }
}
