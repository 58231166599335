const common = {
  NOTE_DATE_FORMAT: "MM/DD/YY",
  NUMBER_OF_CHARTS: 2,
};
const dev = {
  // API_URL: "https://ki06dbln8f.execute-api.us-west-1.amazonaws.com/dev",
  // API_URL: "https://oth8dikz2d.execute-api.us-west-1.amazonaws.com/dev",
  API_URL: "https://oth8dikz2d.execute-api.us-west-1.amazonaws.com/prod",
};

const prod = {
  API_URL: "https://oth8dikz2d.execute-api.us-west-1.amazonaws.com/prod",
};

const config = process.env.REACT_APP_STAGE === "prod" ? prod : dev;
const settings = { ...common, ...config };
export default settings;
