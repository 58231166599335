import { Field } from "formik";
import React from "react";
import { Form } from "react-bootstrap";

const FormikTextArea = ({
  as,
  md,
  size,
  controlId,
  label,
  name,
  type,
  className,
  value,
  placeholder,
  rows,
  required,
}) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <Form.Group md={md} controlId={controlId} className={className}>
            <Form.Label>
              {label} {required && <span className="text-danger">*</span>}
            </Form.Label>
            <Form.Control
              rows={rows}
              as="textarea"
              {...field}
              type={type}
              placeholder={placeholder || label}
              isInvalid={isInvalid}
              feedback={form.errors[field.name]}
              value={field.value}
            />
            <Form.Control.Feedback type="invalid">
              {form.errors[field.name]}
            </Form.Control.Feedback>
          </Form.Group>
        );
      }}
    />
  );
};

FormikTextArea.defaultProps = {
  type: "text",
  inputGroupPrepend: null,
};

export default FormikTextArea;
