import moment from "moment";
import React, { useEffect, useState } from "react";
import { Handles, Rail, Slider, Tracks } from "react-compound-slider";
import { Handle } from "./Handle";
import styles from "./index.module.css";
import { Track } from "./Track";

const railStyle = {
  position: "absolute",
  width: "100%",
  height: 23,
  marginTop: -23,
  border: "1px solid #DFE1E6",
  borderBottom: "none",
  borderRadius: 5,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  backgroundColor: "#FAFCFC",
};

const MultiSlider = ({ options, onChange }) => {
  const [startIndex, setStartIndex] = useState(40);
  const [endIndex, setEndIndex] = useState(60);
  const [domain, setDomain] = useState([0, 100]);

  useEffect(() => {
    if (options?.edgeTicks) {
      setDomain([
        getDomainValue(options?.edgeTicks?.start),
        getDomainValue(options?.edgeTicks?.end),
      ]);
      setStartIndex(getDomainValue(options?.edgeTicks?.start));
      setEndIndex(getDomainValue(options?.edgeTicks?.end));
    }
  }, [options]);

  // This function is used to convert data into needed format based on type
  const getDomainValue = (data) => {
    if (options.edgeTicks.type === "date") {
      return moment.utc(data).valueOf();
    }
  };
  // This function is used to convert date in a format
  const getDisplayTick = (data) => {
    if (options.edgeTicks.type === "date") {
      return moment.utc(data).format(options.edgeTicks.displayFormat);
    }
    return data;
  };
  const onUpdate = (update) => {
    let val = [];
    val[0] = moment.utc(update[0]).startOf("day").valueOf();
    val[1] = moment.utc(update[1]).endOf("day").valueOf();
    if (onChange) {
      onChange(val);
    }
  };

  return (
    <>
      <Slider
        className={`${styles.sliderStyle} d-flex justify-content-center`}
        domain={domain}
        onSlideEnd={onUpdate}
        values={[startIndex, endIndex]}
      >
        {options?.edgeTicks?.start && (
          <div
            className={
              `${styles.edge} ${styles.leftEdge}` /* Add a rail as a child.  Later we'll make it interactive. */
            }
          >
            {getDisplayTick(options?.edgeTicks?.start)}
          </div>
        )}
        <Rail>
          {({ getRailProps }) => <div style={railStyle} {...getRailProps()} />}
        </Rail>

        {options?.edgeTicks?.end && (
          <div
            className={
              `${styles.edge} ${styles.rightEdge}` /* Add a rail as a child.  Later we'll make it interactive. */
            }
          >
            {getDisplayTick(options?.edgeTicks?.end)}
          </div>
        )}
        <Handles>
          {({ handles, getHandleProps, activeHandleID }) => (
            <div className="slider-handles">
              {handles.map((handle) => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  getHandleProps={getHandleProps}
                  isActive={handle.id === activeHandleID}
                  options={options}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks left={false} right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="slider-tracks">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
      </Slider>
    </>
  );
};

export default MultiSlider;
