import { Formik } from "formik";
import moment from "moment-timezone";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import * as yup from "yup";
import { useStores } from "../../hooks/useStores";
import FormikDateTimePicker from "../Forms/FormikDateTimePicker";
import FormikTextArea from "../Forms/FormikTextArea";
import FormikTextField from "../Forms/FormikTextField";

const schema = yup.object({
  note_title: yup.string().required("Title is required"),
  startTime: yup.number().required("Start date is required"),
  endTime: yup.number().nullable(),
  note_description: yup.string(),
});

const UpsertNote = ({ note, clientCode, onSuccess }) => {
  const { interventionNotes } = useStores();
  const [sending, setSending] = useState(false);
  const handleSave = async (values) => {
    setSending(true);
    let data = { ...values };
    if (note.id) {
      data.id = note.id;
    }
    if (!data.endTime) {
      delete data.endTime;
    } else {
      data.endTime = moment(data.endTime)
        .add(moment(data.endTime).utcOffset(), "m")
        .utc()
        .valueOf();
    }
    if (data.startTime) {
      data.startTime = moment(data.startTime)
        .add(moment(data.startTime).utcOffset(), "m")
        .utc()
        .valueOf();
    }
    let response = await interventionNotes.createApiHandler(clientCode, data);
    toast.success(response.message);
    setSending(false);
    onSuccess();
  };
  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleSave}
      initialValues={note}
      enableReinitialize
      validateOnMount
    >
      {({ handleSubmit, isValid, isSubmitting }) => (
        <form noValidate onSubmit={handleSubmit}>
          <FormikTextField
            controlId="note_title"
            label="Title"
            type="text"
            name="note_title"
          />

          <FormikDateTimePicker
            name="startTime"
            label="Start Date"
            timeFormat={false}
            utc={false}
          ></FormikDateTimePicker>

          <FormikDateTimePicker
            name="endTime"
            label="End Date"
            utc={false}
            timeFormat={false}
          ></FormikDateTimePicker>

          <FormikTextArea
            rows={4}
            controlId="note_description"
            label="Description"
            type="text"
            name="note_description"
          ></FormikTextArea>
          <div className="text-center">
            <Button
              disabled={!isValid || isSubmitting || sending}
              variant={!isValid || isSubmitting ? "outline-primary" : "primary"}
              type="submit"
              className="mt-3"
            >
              Save{" "}
            </Button>
          </div>
        </form>
      )}
    </Formik>
  );
};

export default UpsertNote;
